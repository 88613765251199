<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-10">
          <div class="block">
            <p class="paragraph text_green_dark has-text-weight-bold"> Descripción </p>
          </div>
          <div class="block">
            <textarea
              class="textarea"
              v-model="localDescription"
              placeholder="Descripción"
              name="localDescription"
              v-validate="'required'"
              data-vv-as="de la descripción"
            />
            <span v-show="vverrors.has('localDescription')" class="help is-danger">{{ vverrors.first('localDescription') }}</span>
          </div>
        </div>
        <div class="column is-5">
          <div class="block is-flex is-align-items-center">
            <p class="paragraph text_green_dark text_primary_black has-text-weight-bold mr-6"> Estatus </p>
            <span>
              <input
                id="switchStatus"
                type="checkbox"
                name="switchStatusStatus"
                class="switch is-rounded is-small"
                :checked="localStatus ? 'checked' : ''"
                v-model="localStatus"
              >
              <label for="switchStatus"></label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/formats'

export default {
  name: 'CardAddGeneralInformationGuide',
  mixins: [mixins],
  inject: ['$validator'],
  data () {
    return {
      localDescription: null,
      localStatus: false
    }
  },
  methods: {
    getDataGeneralInformation () {
      const dataGeneral = {
        localDescription: this.localDescription,
        localStatus: this.localStatus
      }
      return dataGeneral
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
</style>
